
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({})
export default class EditModal extends Vue {

    @Prop({
        default: () => new OM.ProductionPhaseConfigurationVM()
    }) model: OM.ProductionPhaseConfigurationVM;

    @Prop({
        default: () => []
    }) companies: OM.TextIdentifier[];

    localModel: OM.ProductionPhaseConfigurationVM = new OM.ProductionPhaseConfigurationVM();
    selectedCompany: string = "";

    @Prop() callback: any;

    created() {
        this.localModel = this.model;
    }

    get canSave(){
        return this.localModel.productionPhase;
    }

    save() {
        if(!this.canSave)
            return;

        this.callback(this.localModel);
    }
    
}
